.background-header {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: var(--text2-color);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.15) 5%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.15) 95%
  );
  color: #eee;
  height: 40px;
  font-family: Poppins, sans-serif;
  font-weight: 650;
}
@media screen and (max-width: 578px) {
  .background-header {
    font-size: 12px;
    text-align: center;
  }
}
