.testimonials {
  max-width: 100%;
  align-items: center;
}

.testimonial_item {
  padding: 20px;
  border: 1px solid #d1d1d1c0;
  border-radius: 8px;
  margin: 10px;
  max-width: 950px;
  height: 300px;
  margin-left: 2.3px;
  background-color: #fff;
}

.testimonial_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coverImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.info {
  text-align: center;
}

.testimonial_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-color);
}

.testimonial_location {
  color: #888;
  font-size: 14px;
}

.testimonial_description {
  margin-top: 3px;
  position: relative;
  align-items: center;
  text-align: center;
  font-size: 15px;
}

.title {
  align-items: center;
  margin-top: 20vh;
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: 400;
  color: var(--theme-color);
}

.star {
  color: rgb(197, 97, 97);
}

/* ========================Media Queries for responsiveness======================================= */
@media (max-width: 2560px) {
  .testimonial_item {
    max-width: 1950px;
    height: 300px;
  }
}
@media (max-width: 1880px) {
  .testimonial_item {
    max-width: 140vh;
    height: 50vh;
  }
}
@media (max-width: 1792px) {
  .testimonial_item {
    max-width: 140vh;
    height: 50vh;
    margin: 0 auto;
  }
}
@media (max-width: 912px) {
  .testimonial_item {
    height: 450px;
  }
}

@media (max-width: 768px) {
  .title {
    margin-left: 5%;
    margin-top: 15%;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .title {
    margin-left: 5%;
    margin-top: 10%;
    text-align: center;
  }
}
@media (max-width: 375px) {
  .title {
    margin-left: 9%;
    margin-top: 10%;
  }
}
@media (max-width: 320px) {
  .title {
    margin-left: 5%;
    margin-top: 10%;
  }

  .testimonials {
    margin-bottom: 50%;
  }

  .testimonial_item {
    height: 460px;
  }
}
