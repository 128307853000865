.navbar {
  display: flex;
  height: 7rem;
  width: 100%;
  align-items: center;
  top: 0;
  z-index: 3;
  position: fixed;
  background-color: #fff;
}

ul {
  list-style: none;
}

.logo {
  object-fit: cover;
  max-width: 140%;
  max-height: 140%;
}
.anch_logo {
  width: 180px;
  height: 170px;
}
.anch_nav {
  color: var(--text2-color);
  text-transform: capitalize;
}
.anch_nav:hover {
  color: var(--theme-color);
  text-decoration: underline;
}
.toggle_icon {
  font-size: 32px;
  display: none;
  cursor: pointer;
  color: rgb(65, 78, 150);
  border-color: transparent;
}

.menu {
  display: flex;
  margin-left: auto;
  margin-right: 5rem;
  transition: transform 0.3s ease-in-out; /* Added transition for smooth animation */
  color: var(--text-color);
}

.menu_item {
  margin: 1rem;
  cursor: pointer;
}

.menu_item:hover {
  color: var(--theme-color);
  transition: 0.3s;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 25, "opsz" 24;
}
.toggle_icon.sticky {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000;
}

@media (max-width: 764px) {
  .navbar {
    padding: 0 30px;
  }

  .toggle_icon {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: 5px;
    z-index: 4;
    color: var(--theme-color);
  }

  .toggle_icon.sticky {
    position: fixed;
    top: 28px;
    right: 15px;
    z-index: 1000;
  }

  .menu {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--text-color);
    margin-left: auto;
    height: 100vh;
    padding-top: 100px;
    width: 300px;
    text-align: center;
    box-shadow: -10px 0 20px rgba(0, 0, 0, 0.15);
    z-index: 3;
    color: var(--theme-color);
    margin: 0;
  }

  .anch_nav {
    color: var(--theme-color);
  }
  /* 
  .menu-open {
    height: 500vh;
    position: fixed;
    transform: translateX(-50%);
    transition: transform 0.3s ease-in-out;
    margin-right: 0;
    overflow: hidden;
  } */

  .menu-closed {
    height: 500vh;
    position: fixed;
    transform: translateY(120%);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    margin-right: auto;
  }

  .logo {
    object-fit: cover;
    max-width: 140%;
    max-height: 120%;
    margin-left: -40px;
  }
  .anch_logo {
    width: 150px;
    height: 150px;
  }
  .menu_item {
    display: block;
  }
  .menu,
  active-link {
    display: block;
  }

  .toggle_icon {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: 5px;
    z-index: 4;
    color: var(--theme-color);
  }
}

@media (max-width: 578px) {
  .navbar {
    padding: 0 15px; /* Adjust padding for spacing */
  }

  .toggle_icon {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: 20px;
  }
}
