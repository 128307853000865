.section_explore {
  margin-top: 16vh;
  max-width: 100%;
  height: 40rem;
  margin-bottom: 12%;
}

.h1_explore {
  font-size: 40px;
  font-weight: 400;
  color: var(--theme-color);
  position: relative;
  top: -20px;
  max-width: 100%;
  left: 38%;
  margin-bottom: 1%;
}

.sliders {
  position: relative;
}

.slider_img {
  height: 700px;
  object-fit: cover;
  width: 100%;
  position: relative;
}

.circle {
  background-color: var(--theme-color);
  height: 48px;
  width: 48px;
  color: black;
  border-radius: 10% 0% 0% 0%;
  margin-left: 2.2%;
}

.number {
  background-color: #fff;
  position: absolute;
  height: 50px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  top: 9.2%;
  left: 7.2%;
  font-size: 20px;
  font-weight: 450;
  color: var(--theme-color);
  border-radius: 10% 0% 0% 0%;
}

.explore1 {
  position: absolute;
  top: 45%;
  left: 157%;
  width: 450px;
  height: 250px;
  background-color: #fff;
  opacity: 95%;
  padding: 20px;
  border-radius: 20px 20px 100px 15px;
  z-index: 2;
  max-width: 100%;
}

.explore1_text {
  position: absolute;
  top: 35%;
  font-weight: 500;
  font-size: 24px;
  color: var(--text-color);
  left: 6%;
}

.explore1_p {
  position: absolute;
  top: 50%;
  margin-top: 8px;
  margin-right: 20%;
  left: 6%;
  font-weight: 300;
  font-size: 16px;
  color: var(--text2-color);
}

/* ==========================MEDIA================================================= */
/* ==========================MEDIA================================================= */
/* ==========================MEDIA================================================= */

@media (max-width: 1024px) {
  .h1_explore {
    left: 25.5%;
  }
  .explore1 {
    top: 60%;
    left: 158%;
    width: 400px;
    height: 250px;
  }
  .circle {
    background-color: var(--theme-color);
    height: 48px;
    width: 48px;
    color: black;
    border-radius: 10% 0% 0% 0%;
    margin-left: 1.8%;
  }
}
@media (max-width: 768px) {
  .h1_explore {
    left: 17%;
  }
  .explore1 {
    top: 60%;
    left: 147%;
    width: 400px;
    height: 250px;
  }

  .circle {
    background-color: var(--theme-color);
    height: 48px;
    width: 48px;
    color: black;
    border-radius: 10% 0% 0% 0%;
    margin-left: 1.8%;
  }
}
@media (max-width: 540px) {
  .explore1 {
    top: 60%;
    left: 120%;
    width: 400px;
    height: 250px;
  }
  .h1_explore {
    left: 5%;
  }
}

@media (max-width: 430px) {
  .section_explore {
    margin-top: 3%;
  }
  .h1_explore {
    left: 22%;
  }

  .slider_img {
    height: 500px;
    object-fit: cover;
    width: 100%;
    position: relative;
  }

  .explore1 {
    position: absolute;
    top: 69%;
    left: 102%;
    width: 350px;
    height: 150px;
    background-color: #fff;
    opacity: 95%;
    padding: 20px;
    border-radius: 20px 20px 100px 15px;
    z-index: 2;
    max-width: 100%;
  }
  .circle {
    background-color: var(--theme-color);
    height: 30px;
    width: 30px;
    color: black;
    border-radius: 50%;
    margin-left: 85%;
  }
  .number {
    background-color: transparent;
    color: rgb(252, 252, 252);
    margin-left: 71%;
    font-size: 14px;
    margin-top: -0.6%;
  }
  .explore1_text {
    position: absolute;
    top: 20%;
    font-weight: 500;
    font-size: 15px;
    color: var(--text-color);
    left: 6%;
  }

  .explore1_p {
    top: 35%;
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .h1_explore {
    left: 19%;
  }
}

@media (max-width: 320px) {
  .h1_explore {
    margin: 0 auto;
    width: 100%;
    position: relative;
    left: 12%;
  }
  .section_explore {
    margin-top: 5%;
  }
  .slider_img {
    height: 500px;
    object-fit: cover;
    width: 100%;
    position: relative;
  }

  .explore1 {
    position: absolute;
    top: 69%;
    left: 102%;
    width: 300px;
    height: 140px;
    background-color: #fff;
    opacity: 95%;
    padding: 20px;
    border-radius: 20px 20px 100px 15px;
    z-index: 2;
    max-width: 100%;
  }
  .circle {
    background-color: var(--theme-color);
    height: 30px;
    width: 30px;
    color: black;
    border-radius: 50%;
    margin-left: 85%;
  }
  .number {
    background-color: transparent;
    color: rgb(252, 252, 252);
    margin-left: 69.7%;
    font-size: 14px;
    margin-top: -0.5%;
  }
  .explore1_text {
    position: absolute;
    top: 20%;
    font-weight: 500;
    font-size: 15px;
    color: var(--text-color);
    left: 6%;
  }

  .explore1_p {
    top: 35%;
    font-size: 13px;
  }
}
