.section_apartments {
  max-width: 100%;
  margin-top: 200px;
  transition: max-width 0.3s ease, margin-top 0.3s ease;
}

.cards-list {
  justify-content: center;
  transform: translateX(0);
  opacity: 1;
  max-width: 100%;
  display: grid;
  transition: background-color 0.5s ease;
}

.card--image-container {
  overflow: hidden;
  border-radius: 9px;
}

.card {
  max-width: 100%;
  font-size: 16px;
  margin-right: 1vh;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 400px;
}

.card--image {
  height: 300px;
  border-radius: 9px;
  margin-bottom: 9px;
  object-fit: cover;
}

.first-set {
  display: flex;
  gap: 2vh;
  align-items: center;
}

.next-set {
  margin-top: -15vh;
  display: flex;
  gap: 2vh;
}

.card--title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(27, 26, 26);
}

.card--stats {
  display: flex;
  align-items: center;
}

.card--price {
  margin-top: auto;
}

.next-set.active {
  transform: translateX(5%);
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.anch_apartments {
  color: var(--theme-color);
  text-decoration: none;
  transition: text-decoration 0.3s;
}

.anch_apartments:hover {
  text-decoration: underline;
}

.dot-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  border-radius: 50%;
  background-color: #d6d6d6b0;
  margin: 52px 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 3px;
}

.dot.active {
  background-color: var(--text2-color);
}

.dot:hover {
  transform: scale(1.2);
}

/* =============================Media query for screens smaller than 768px===================================== */
@media only screen and (max-width: 1241px) {
  .section_apartments {
    margin-top: 10vh;
  }

  .card {
    top: 4rem;
    left: 0.5rem;
    width: 47vh;
  }
  .next-set {
    margin-top: -6vh;
  }
}

@media only screen and (max-width: 1167px) {
  .card {
    width: 45vh;
  }
}
@media only screen and (max-width: 1123px) {
  .card {
    width: 42vh;
  }
}
@media only screen and (max-width: 1044px) {
  .card {
    width: 38vh;
  }
}

@media only screen and (max-width: 960px) {
  .card {
    width: 95vh;
    max-height: 100%;
  }
  .first-set,
  .next-set {
    display: grid;
    grid-gap: 0.01px;
  }
}

@media (max-width: 912px) {
  .card--image {
    height: 55vh;
  }
}
@media (max-width: 810px) and (max-height: 1080px) {
  .card {
    width: 63vh;
  }

  .card--image {
    height: 450px;
  }
}

@media only screen and (max-width: 768px) {
  .section_apartments {
    margin-left: 2%;
  }

  .card--image {
    height: 600px;
  }
  .first-set,
  .next-set {
    display: grid;
    justify-content: center;
    align-content: center;
  }
  .card {
    width: 90vh;
    left: 3px;
  }
}

@media only screen and (max-width: 680px) {
  .card {
    width: 75vh;
    left: 0.1rem;
  }
}
@media (max-width: 540px) {
  .card {
    width: 60vh;
  }
  .card--image {
    height: 50vh;
  }
  .previous_btn {
    left: 75%;
  }
  .next_btn {
    left: 73%;
  }
}

@media only screen and (max-width: 508px) {
  .card {
    width: 55vh;
  }
}
@media (max-width: 431px) and (max-height: 950px) {
  .card {
    width: 45vh;
  }
}

@media only screen and (max-width: 426px) {
  .card {
    width: 45vh;
  }
}
@media (max-width: 430px) {
  .card--image {
    height: 35vh;
  }
  .next_btn,
  .previous_btn {
    top: 80px;
    left: 37%;
  }
}
@media (max-width: 425px) {
  .card--image {
    height: 300px;
  }
  .next_btn,
  .previous_btn {
    top: 80px;
    left: 37%;
  }
}

@media only screen and (max-width: 346px) {
  .card {
    width: 35vh;
  }
  .previous_btn {
    left: 29%;
  }
}

@media (max-width: 768px) and (min-height: 1024px) {
  .card {
    width: 70vh;
    margin-top: 20%;
    margin-left: -2%;
  }
}
@media (max-width: 820px) and (min-height: 1180px) {
  .card {
    width: 60vh;
    margin-top: 25%;
  }
}

@media (max-width: 853px) and (min-height: 1280px) {
  .card {
    width: 60vh;
    margin-top: 28%;
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .card {
    width: 22vh;
    margin-top: 28%;
  }
}

@media (max-width: 1024px) and (min-height: 1368px) {
  .card {
    width: 60vh;
    margin-top: 28%;
  }
}
