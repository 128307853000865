.contact_section {
  padding-bottom: 60px;
  background-color: var(--main-color);
  margin-top: 7%;
}

.h1_contact {
  font-size: 40px;
  font-weight: 400;
  color: var(--theme-color);
  position: relative;
  top: -20px;
  max-width: 100%;
  left: 43%;
  margin-bottom: 1%;
}

.wrapper {
  background-color: #f0ede9;
  border-radius: 1.5%;
  height: 600px;
  width: 1200px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
}
.left_content {
  width: 600px;
  margin-top: 5%;
}
.host_box {
  margin: 0 auto;
  height: 300px;
  width: 400px;
  position: relative;
  right: 65px;
  top: 20px;
  border-radius: 5%;
  box-shadow: 2px 2px 10px 5px #d8cdc7;
  background-color: #fff;
}
.host_anch {
  color: var(--text-color);
}
.host_info {
  margin: 0 auto;
  width: 100%;
  height: 24vh;
  color: var(--text2-color);
}
.info_img {
  width: 50%;
  position: relative;
  left: 30px;
  top: 50px;
}

.info_h2 {
  font-size: 26px;
  position: relative;
  left: 82px;
  bottom: -5px;
  color: var(--text-color);
}

.info_p {
  position: relative;
  bottom: -5px;
  left: 82px;
}

.info_icon {
  color: rgba(221, 51, 51, 0.605);
}

.info_ratings {
  position: relative;
  left: 270px;
  top: -170px;
  font-size: 18px;
}

.below_info {
  font-size: 15px;
}

.reviews_count {
  color: var(--text-color);
}

.reviews_p {
  color: var(--text2-color);
}

.rating_count {
  color: var(--text-color);
}

.rating_p {
  color: var(--text2-color);
}

.years_count {
  color: var(--text-color);
}

.years_p {
  color: var(--text2-color);
}
.contact_line {
  width: 100px;
  margin-top: 2%;
  margin-bottom: 2%;
  opacity: 40%;
}

.below_host_box {
  width: 400px;
  left: 20px;
  top: 50px;
  min-height: 30%;
  position: relative;
}

.luxenia_info {
  margin-top: 8%;
  font-size: 15px;
}

.more {
  color: var(--text-color);
  font-weight: 600;
}
.more:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.more_icon {
  position: relative;
  top: 2px;
  right: -0.2%;
}

.right_content {
  width: 600px;
  margin-top: 5%;
}

.right_h1 {
  font-size: 21px;
  color: var(--text-color);
  margin-top: 15px;
}

.right_p {
  font-size: 15px;
  margin-top: 10px;
}

.right_h2 {
  font-size: 21px;
  color: var(--text-color);
  margin-top: 25px;
}

.right-img2 {
  margin-top: 12px;
  width: 50px;
  border-radius: 50%;
}

.emil2 {
  color: var(--text2-color);
  top: -16px;
  left: 5px;
  position: relative;
}

.emil:hover,
.emil2:hover {
  color: var(--theme-color);
  transition: 0.2s;
}

.contact_btn {
  background-color: var(--text2-color);
  border: 2px solid transparent;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.4s;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  margin-left: -0.6%;
  width: 350px;
}
.contact_a {
  color: #fff;
}
.contact_a:hover {
  color: var(--text2-color);
}

.contact_btn:hover {
  background-color: #f0ede9;
  color: var(--text2-color);
  border-color: var(--text2-color);
}
/* ===========================MEDIA========================================================= */
/* ===========================MEDIA========================================================= */
/* ===========================MEDIA========================================================= */

@media (max-width: 1024px) {
  .h1_contact {
    left: 35%;
  }
  .host_box {
    right: 30px;
  }
}
@media (max-width: 820px) {
  .h1_contact {
    left: 30%;
  }
  .host_box {
    right: 20px;
  }
  .below_host_box {
    margin-left: -4%;
  }

  .right_content {
    width: 300px;
    margin-top: 6%;
  }
  .emil,
  .emil2 {
    font-size: 13px;
  }
  .contact_btn {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .h1_contact {
    left: 30%;
  }
  .host_box {
    right: 20px;
  }
  .below_host_box {
    margin-left: -8%;
  }

  .right_content {
    width: 300px;
    margin-top: 6%;
  }
  .emil,
  .emil2 {
    font-size: 13px;
  }
  .contact_btn {
    width: 300px;
  }
}
@media (max-width: 540px) {
  .contact_section {
    margin-top: 120px;
  }
  .host_box {
    right: 118px;
  }
  .h1_contact {
    left: 18%;
    font-size: 38px;
    top: -60px;
  }
  .wrapper {
    background-color: #f0ede9;
    height: 1070px;
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .below_host_box {
    margin-left: -5%;
  }

  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: -2.7%;
  }
  .emil,
  .emil2 {
    font-size: 14px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
  .contact_btn {
    width: 330px;
  }
}
@media (max-width: 430px) {
  .contact_section {
    margin-top: 120px;
  }
  .host_box {
    right: 118px;
  }
  .h1_contact {
    left: 18%;
    font-size: 38px;
    top: -60px;
  }
  .wrapper {
    background-color: #f0ede9;
    height: 1070px;
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .below_host_box {
    margin-left: -5%;
  }

  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: -2.7%;
  }
  .emil,
  .emil2 {
    font-size: 14px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
  .contact_btn {
    width: 330px;
  }
}

@media (max-width: 425px) {
  .contact_section {
    margin-top: 120px;
  }
  .host_box {
    right: 118px;
  }
  .h1_contact {
    left: 18%;
    font-size: 38px;
    top: -60px;
  }
  .wrapper {
    background-color: #f0ede9;
    height: 1070px;
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .below_host_box {
    margin-left: -5%;
  }

  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: -2.7%;
  }
  .emil,
  .emil2 {
    font-size: 14px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
  .contact_btn {
    width: 330px;
  }
}

@media (max-width: 414px) {
  .host_box {
    width: 390px;
    right: 125px;
  }
}

@media (max-width: 400px) {
  .contact_section {
    margin-top: 120px;
  }
  .h1_contact {
    left: 18%;
    font-size: 32px;
    top: -60px;
  }
  .wrapper {
    background-color: #f0ede9;
    height: 1070px;
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .host_box {
    margin: 0 auto;
    height: 330px;
    width: 320px;
    position: relative;
    right: 135px;
    top: 20px;
    box-shadow: 2px 2px 10px 5px #d8cdc7;
    background-color: #fff;
  }

  .host_info {
    margin: 0 auto;
    width: 80%;
    height: 24vh;
  }
  .info_img {
    width: 85%;
    position: relative;
    top: -8px;
    left: 19px;
  }

  .info_h2 {
    font-size: 26px;
    position: relative;
    left: 75px;
    top: -50px;
    color: var(--text-color);
  }

  .info_p {
    position: relative;
    left: 77px;
    top: -55px;
  }

  .info_icon {
    color: rgba(221, 51, 51, 0.605);
  }

  .info_ratings {
    position: relative;
    left: -9px;
    top: -45px;
    font-size: 14px;
    gap: 10px;
    width: 150px;
  }
  .contact_line {
    width: 100%;
  }
  .below_host_box {
    margin-left: -1.8%;
  }
  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: 2.5%;
  }
  .emil,
  .emil2 {
    font-size: 14px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
  .contact_btn {
    width: 305px;
  }
}
@media (max-width: 375px) {
  .contact_section {
    margin-top: 120px;
  }
  .h1_contact {
    left: 18%;
    font-size: 32px;
    top: -60px;
  }
  .wrapper {
    background-color: #f0ede9;
    height: 1070px;
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .host_box {
    margin: 0 auto;
    height: 330px;
    width: 300px;
    position: relative;
    right: 140px;
    top: 20px;
    box-shadow: 2px 2px 10px 5px #d8cdc7;
    background-color: #fff;
  }

  .host_info {
    margin: 0 auto;
    width: 80%;
    height: 24vh;
  }
  .info_img {
    width: 85%;
    position: relative;
    top: -8px;
    left: 19px;
  }

  .info_h2 {
    font-size: 26px;
    position: relative;
    left: 75px;
    top: -50px;
    color: var(--text-color);
  }

  .info_p {
    position: relative;
    left: 77px;
    top: -55px;
  }

  .info_icon {
    color: rgba(221, 51, 51, 0.605);
  }

  .info_ratings {
    position: relative;
    left: -9px;
    top: -45px;
    font-size: 14px;
    gap: 10px;
    width: 150px;
  }
  .contact_line {
    width: 100%;
  }
  .below_host_box {
    margin-left: -1.8%;
  }
  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: 2.5%;
  }
  .emil,
  .emil2 {
    font-size: 14px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
  .contact_btn {
    width: 305px;
  }
}
@media (max-width: 360px) {
  .host_box {
    height: 330px;
    right: 150px;
  }
}
@media (max-width: 320px) {
  .h1_contact {
    left: 14%;
    font-size: 32px;
    top: -60px;
  }

  .wrapper {
    background-color: #f0ede9;
    height: 1050px;
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .host_box {
    margin: 0 auto;
    height: 320px;
    width: 270px;
    position: relative;
    right: 170px;
    top: 20px;
  }

  .host_info {
    margin: 0 auto;
    width: 80%;
    height: 24vh;
  }
  .info_img {
    width: 85%;
    position: relative;
    top: -8px;
    left: 17px;
  }

  .info_h2 {
    font-size: 26px;
    position: relative;
    left: 60px;
    top: -50px;
    color: var(--text-color);
  }

  .info_p {
    position: relative;
    left: 61px;
    top: -55px;
  }

  .info_icon {
    color: rgba(221, 51, 51, 0.605);
  }

  .info_ratings {
    position: relative;
    left: -9px;
    top: -45px;
    font-size: 14px;
    gap: 10px;
    width: 150px;
  }
  .contact_line {
    width: 100%;
  }
  .below_host_box {
    margin-left: -3.5%;
  }
  .right_content {
    width: 300px;
    margin-top: 25%;
    margin-left: -0.8%;
  }
  .emil,
  .emil2 {
    font-size: 12px;
  }

  .contact_btn {
    width: 270px;
    margin-left: -1%;
  }

  .luxenia_info {
    width: 300px;
  }

  .estimated_response {
    font-size: 15px;
  }
}
