.section_hero {
  background-image: url("/public/assets/HeroBackground.jpg");
  background-size: cover;
  background-position: center;
  height: 950px;
  border-radius: 7px 0px 0px 180px;
  margin-left: 1.5rem;
  z-index: 1;
  position: relative;
  margin-top: 100px;
}

.hero1 {
  position: absolute;
  display: block;
  left: 42%;
  top: -0.1%;
  transform: translateX(-100%);
  width: 600px;
  height: 400px;
  background-color: #fff;
  z-index: 999;
  border-radius: 0px 0px 100px 15px;
}
.hero1_containers {
  margin-left: 8%;
  margin-top: 3%;
}

.hero_text {
  font-weight: 400;
  font-size: 45px;
  color: var(--text2-color);
}
.hero_p {
  margin-top: 2%;
  color: var(--text-color);
  max-width: 490px;
}
.hero_btn {
  background-color: var(--text2-color);
  border: 2px solid transparent;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.4s;
  margin-top: 50px;
  position: relative;
  cursor: pointer;
  margin-left: -0.6%;
}
.hero_a {
  color: #fff;
}
.hero_a:hover {
  color: var(--text2-color);
}

.hero_btn:hover {
  background-color: white;
  color: var(--text2-color);
  border-color: var(--text2-color);
}

/* ==================================================MEDIA========================================*/

@media screen and (max-width: 1600px) {
  .section_hero {
    background-size: 1575px;
  }
}

@media screen and (max-width: 1450px) {
  .hero1 {
    left: -0.1%;
    transform: none;
  }
  .section_hero {
    background-size: 2000px;
  }
  .hero1 {
    border-radius: 0px 0px 180px 0px;
  }
}

@media screen and (max-width: 764px) {
  .section_hero {
    background-size: 578px;
    background-position-y: 9cm;
    height: 34vh;
    width: 95%;
    border-radius: 0px 7px 180px 0px;
    margin-left: 0rem;
  }
  .hero1 {
    position: absolute;
    display: block;
    left: -4%;
    top: 15%;
    transform: translateY(100%);
    width: 600px;
    height: 400px;
    background-color: #fff;
    z-index: 999;
    border-radius: 0px 0px 100px 15px;
    max-width: 100%;
    max-height: 100%;
  }
  .hero1_containers {
    margin-left: 8%;
    margin-top: 1%;
    max-width: 100%;
    max-height: 100%;
  }

  .hero_text {
    font-weight: 500;
    font-size: 30px;
    color: #504538;
  }
  .hero_p {
    margin-top: 2%;
    font-size: 14px;
  }
  .hero_btn {
    width: 60vh;
    border-radius: 30px;
  }
}
@media screen and (max-width: 578px) {
  .section_hero {
    background-size: 578px;
    background-position-y: 9cm;
    height: 34vh;
    width: 100%;
    border-radius: 0px 7px 180px 0px;
    margin-left: 0rem;
  }

  .hero1 {
    left: -4%;
    height: 100%;
    transform: translateY(100%);
    width: 100%; /* Adjust width as needed */
    height: 400px; /* Adjust height as needed */
    border-radius: 0px 0px 100px 15px;
  }

  .hero1_containers {
    margin-left: 8%;
    margin-top: 1%;
  }

  .hero_text {
    font-weight: 500;
    font-size: 30px;
    color: #504538;
  }

  .hero_p {
    margin-top: 2%;
    font-size: 14px;
  }

  .hero_btn {
    width: 60vh;
    border-radius: 30px;
    right: auto;
    max-width: 100%;
  }
}

@media (max-width: 425px) {
  .section_hero {
    height: 40vh;
  }
  .hero1 {
    height: 95%;
  }
}
/* ==================================================MEDIA HEIGHT========================================*/
