.footer_wrapper {
  border-top: 1px solid var(--theme-color);
  height: 300px;
  width: 100%;
  max-width: 100%;
  padding: 0 2rem;
  background-color: var(--text-color);
}

.flex_items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left1_content {
  width: 600px;
  right: 90px;
  top: 20px;
  position: relative;
}
.p1_footer {
  font-size: 15px;
  margin-top: 7px;
  color: #777777;
}
.middle_content {
  width: 200px;
}

.right1_content {
  width: 200px;
  position: relative;
  left: 40px;
}

.footer_line {
  width: 1050px;
  margin: 20px auto;
  position: relative;
  right: 67px;
  top: 30px;
}

.h1_left {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.h1_middle {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  top: 16px;
  color: #fff;
}

.h1_right {
  font-size: 20px;
  font-weight: 500;
  top: 32px;
  position: relative;
  color: #fff;
}

.ul_middle {
  top: 22px;
  position: relative;
  font-size: 15px;
  color: #8d8585;
}

.ul_right {
  top: 36px;
  color: #8d8585;
  position: relative;
}

.footer_copy {
  font-size: 13px;
  position: relative;
  right: 442px;
  top: 6%;
  color: #8d8585;
  margin: 0 auto;
  width: 300px;
}

.footer_menu {
  color: #8d8585;
  font-size: 15px;
}

.footer_menu:hover {
  color: rgb(226, 193, 149);
  transition: 0.4s;
}

@media (max-width: 1024px) {
  .left1_content {
    width: 420px;
    right: 60px;
    top: 40px;
  }
  .footer_wrapper {
    height: 340px;
  }
  .footer_line {
    width: 850px;

    right: 45px;
    top: 40px;
  }

  .footer_copy {
    top: 8%;
    right: 320px;
  }
}
@media (max-width: 912px) {
  .footer_wrapper {
    height: 370px;
  }
  .left1_content {
    width: 380px;
    right: 45px;
    top: 30px;
    position: relative;
    left: 10px;
  }
  .middle_content {
    position: relative;
    left: 40px;
    top: -20px;
  }
  .right1_content {
    position: relative;
    top: -20px;
  }
  .footer_line {
    width: 733px;
    margin: 20px auto;
    position: relative;
    right: 13px;
    top: 40px;
    color: #fff;
  }

  .footer_copy {
    top: 8%;
    right: 230px;
  }
}
@media (max-width: 810px) {
  .footer_wrapper {
    height: 370px;
  }
  .left1_content {
    width: 380px;
    right: 45px;
    top: 30px;
    position: relative;
    left: 10px;
  }
  .middle_content {
    position: relative;
    left: 40px;
    top: -20px;
  }
  .right1_content {
    position: relative;
    top: -20px;
  }
  .footer_line {
    width: 700px;
    margin: 20px auto;
    position: relative;
    right: 13px;
    top: 40px;
    color: #fff;
  }

  .footer_copy {
    top: 8%;
    right: 215px;
  }
}

@media (max-width: 768px) {
  .footer_wrapper {
    height: 390px;
  }
  .left1_content {
    width: 380px;
    right: 45px;
    top: 30px;
    position: relative;
    left: 10px;
  }
  .middle_content {
    position: relative;
    left: 40px;
    top: -20px;
  }
  .right1_content {
    position: relative;
    top: -20px;
  }
  .footer_line {
    width: 700px;
    margin: 20px auto;
    position: relative;
    right: -5px;
    top: 40px;
    color: #fff;
  }

  .footer_copy {
    top: 8%;
    right: 195px;
  }
}

@media (max-width: 540px) {
  .footer_wrapper {
    height: 800px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -35%;

    position: relative;
  }

  .p1_footer {
    font-size: 16px;
    margin: 0 auto;
    width: 120%;
  }
  .middle_content {
    margin-top: 15%;
    margin-left: -46%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -46%;
  }

  .footer_line {
    width: 340px;
    margin: 20px auto;
    position: relative;
    right: 58px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 2.5%;
    right: 80px;
  }
}
@media (max-width: 430px) {
  .footer_wrapper {
    height: 800px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -20%;

    position: relative;
  }

  .p1_footer {
    font-size: 16px;
    margin: 0 auto;
    width: 120%;
  }
  .middle_content {
    margin-top: 12%;
    margin-left: -32%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -32%;
  }

  .footer_line {
    width: 340px;
    margin: 20px auto;
    position: relative;
    right: 22px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 2.5%;
    right: 43px;
  }
}
@media (max-width: 425px) {
  .footer_wrapper {
    height: 810px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -20%;

    position: relative;
  }

  .p1_footer {
    font-size: 16px;
    margin: 0 auto;
    width: 120%;
  }
  .middle_content {
    margin-top: 20%;
    margin-left: -32%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -32%;
  }

  .footer_line {
    width: 340px;
    margin: 20px auto;
    position: relative;
    right: 22px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 2.5%;
    right: 43px;
  }
}
@media (max-width: 400px) {
  .footer_wrapper {
    height: 820px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -18%;

    position: relative;
  }

  .p1_footer {
    font-size: 16px;
    margin: 0 auto;
    width: 120%;
  }
  .middle_content {
    margin-top: 25%;
    margin-left: -29%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -29%;
  }

  .footer_line {
    width: 340px;
    margin: 20px auto;
    position: relative;
    right: 19px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 2.5%;
    right: 34px;
  }
}
@media (max-width: 384px) {
  .footer_wrapper {
    height: 840px;
  }

  .footer_copy {
    right: 30px;
  }
}
@media (max-width: 375px) {
  .footer_wrapper {
    height: 790px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -6%;
    position: relative;
  }

  .p1_footer {
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto;
  }

  .middle_content {
    margin-top: 29%;
    margin-left: -18%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -18%;
  }

  .footer_line {
    width: 270px;
    margin: 20px auto;
    position: relative;
    right: 18px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 4%;
    right: 4px;
  }
}

@media (max-width: 320px) {
  .footer_wrapper {
    height: 720px;
  }

  .flex_items {
    display: grid;
  }

  .left1_content {
    width: 280px;
    margin-left: -4%;
    position: relative;
  }

  .p1_footer {
    font-size: 12.5px;
    max-width: 100%;
    margin: 0 auto;
  }

  .middle_content {
    margin-top: 25%;
    margin-left: -16%;
  }

  .right1_content {
    margin-top: 1%;
    margin-left: -17%;
  }

  .footer_line {
    width: 270px;
    margin: 20px auto;
    position: relative;
    right: 20px;
    top: 30px;
    color: #fff;
  }

  .footer_copy {
    top: 4%;
    right: 20px;
  }
}
