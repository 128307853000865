.between_container {
  max-width: 70rem;
  margin: 0 auto;
  margin-bottom: -120px;
}

.h1_between {
  font-size: 40px;
  font-weight: 400;
  color: var(--theme-color);
  position: relative;
  top: -22px;
  text-align: center;
}

.h2_between {
  font-size: 40px;
  font-weight: 400;
  color: var(--theme-color);
  position: relative;
  top: -10px;
  text-align: center;
}
.p_between {
  padding: 5px;
  position: relative;
  top: -16px;
  margin-right: 8em;
  left: 8vh;
  text-align: center;
  color: var(--text-color);
}

.p2_between {
  padding: 5px;
  position: relative;
  margin-right: 100px;
  left: 6vh;
  text-align: center;
  color: var(--text-color);
}

/*===========================Media queries for responsiveness======================================*/

@media (max-width: 1440px) {
  .between_container {
    margin-top: 15%;
  }
}

@media (max-width: 1024px) {
  .between_container {
    margin-top: 20%;
  }
}
@media (max-width: 912px) {
  .between_container {
    margin-top: 30%;
  }
}
@media (max-width: 820px) {
  .between_container {
    margin-top: 30%;
  }
}
@media (max-width: 768px) {
  .between_container {
    margin-top: 30%;
  }
}
@media only screen and (max-width: 764px) {
  .between_container {
    height: auto;
    padding-top: 20rem;
    padding-bottom: -1rem;
  }
  .h1_between {
    font-size: 31px;
    top: -15px;
    max-width: 100%;
  }

  .h2_between {
    font-size: 32px;
    top: -5px;
    max-width: 100%;
  }

  .p_between,
  .p2_between {
    top: -12px;
    left: 0;
    margin-right: 0;
  }
  .p2_between {
    margin-bottom: 7%;
  }
}

@media only screen and (max-width: 565px) {
  .h1_between {
    font-size: 30px;
  }
}

@media (max-width: 540px) {
  .between_container {
    margin-top: -30%;
  }
}

@media only screen and (max-width: 535px) {
  .h1_between {
    font-size: 26px;
  }
}
@media (max-width: 430px) and (min-height: 932px) {
  .between_container {
    margin-top: 3%;
  }
}

@media (max-width: 360px) and (min-height: 740px) {
  .between_container {
    margin-top: 6%;
  }
}

@media (max-width: 430px) {
  .between_container {
    margin-top: -65%;
  }
  .h1_between {
    font-size: 36px;
  }
}

@media (max-width: 425px) {
  .between_container {
    margin-top: -65%;
  }
  .p2_between {
    margin-top: 3%;
  }
  .h1_between {
    font-size: 38px;
  }
}
@media (max-width: 375px) {
  .between_container {
    margin-top: -65%;
  }
  .p2_between {
    margin-top: 3%;
  }
  .h1_between {
    font-size: 38px;
  }
}

@media (max-width: 320px) {
  .between_container {
    margin-top: -80%;
  }
  .h1_between {
    width: 100%;
    font-size: 28px;
  }
  .p_between {
    margin-top: 10%;
  }
  .p2_between {
    margin-top: 6%;
  }
}
